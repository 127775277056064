@keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@keyframes gradientBorder {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }



  .gradient-border {
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #3745ab, #232f8d );
    background-size: 300% 300%;
    animation: gradientBorder 3s ease infinite;
  }

  
  .grid-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      linear-gradient(to right, rgba(99, 102, 241, 0.1) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(99, 102, 241, 0.1) 1px, transparent 1px);
    background-size: 50px 50px;
    animation: moveGrid 15s linear infinite;
    mask-image: radial-gradient(circle, black 50%, transparent 100%);
  }
  
  @keyframes moveGrid {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(50px);
    }
  }
  
  .animate-pulse {
    animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.2;
    }
  }
  
  .frosted {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  
.text-clip {
    /* Fallback for browsers that don't support text clipping */
    color: transparent;

    /* WebKit-based browsers (Chrome, Safari, Edge, etc.) */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    /* Firefox-specific support */
    background-clip: text; /* Standard property (partial support in Firefox) */
    -moz-background-clip: text; /* Older Firefox versions */

    /* Additional fallback for Safari */
    background-color: transparent; /* Ensures no background behind the text */
}

.text-gradient-primary {
    background-image: linear-gradient(to right, #252f80, #7a84d0, #3745ab);
}
